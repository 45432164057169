<template>
  <b-card-actions ref="card" :title="$t('Document Section')" action-refresh @refresh="refresh">

    <b-form @submit.prevent>
      <b-row>

        <!-- first name -->
        <b-col cols="12">
          <b-form-group
              :label="$t('name')"
              label-for="name"
          >
            <b-form-input
                id="name" :disabled="editDisabled"
                :placeholder="$t('name')"
                v-model="document_section.name"
            />
          </b-form-group>
        </b-col>
      </b-row><b-row>
        <!-- description -->
        <b-col cols="12">
          <b-form-group
              :label="$t('description')"
              label-for="description"
          >
            <b-form-textarea
                id="description" :disabled="editDisabled"
                :placeholder="$t('description')"
                v-model="document_section.description"
            />
          </b-form-group>
        </b-col>
    </b-row>
      <b-row>
        <!-- Field: Assigned to -->
        <b-col
            cols="12"
            md="12"
        >
          <b-form-group
              :label="$t('Parent Section')"
              label-for="user-role"
          >
            <b-dropdown
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :text="currentParent.name"
                variant="outline-primary"
                block
                :disabled="allEditDisabled"
            >
              <b-dropdown-item
                  v-for="document_section in document_sections"
                  :key="document_section.id"
                  @click="setParent(document_section)"
              >
                <span class="ml-50">{{ document_section.name }} </span>
              </b-dropdown-item>

            </b-dropdown>
          </b-form-group>
        </b-col>

      </b-row>
      <b-row>


        <!-- PERMISSION TABLE -->
        <b-card
            no-body
            class="border mt-1 ml-1"
            v-if="permissionEnabled"
        >
          <b-card-header class="p-1">
            <b-card-title class="font-medium-2">
              <feather-icon
                  icon="LockIcon"
                  size="18"
              />
              <span class="align-middle ml-50"> {{ $t('Permissions') }}</span>
            </b-card-title>
          </b-card-header>
          <b-table
              striped
              responsive
              class="mb-0"
              ref="permissionTable"
              :fields="permission_fields"
              :items="permissionsData"
          >
            <template #cell(name)="row">
              {{ row.item.name }}
            </template>
            <template #cell(read)="row">
              <b-form-checkbox :disabled="editDisabled || row.item.myself"
                               v-model="row.item.read"></b-form-checkbox>
            </template>
            <template #cell(write)="row">
              <b-form-checkbox :disabled="editDisabled|| row.item.myself"
                               v-model="row.item.write"></b-form-checkbox>
            </template>


          </b-table>
        </b-card>


        <!-- submit and reset -->
        <b-col cols="12">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1" :disabled="editDisabled"
              @click.prevent.stop="handleSubmit($event)"
          >
            {{ $t('Save') }}
          </b-button>
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              @click.prevent.stop="back($event)"

          >
            {{ $t('Cancel') }}

          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card-actions>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BFormTextarea,
  BTable,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import UserService from "@/basic/userService";
import useJwt from "@/auth/jwt/useJwt";
import store from "@/store";
import {codeI18n} from "@/views/table/vue-good-table/code";
import {useRouter} from "@core/utils/utils";
import {BCardActions} from "@core/components/b-card-actions";
import i18n from '@/libs/i18n'
import Spacer from "@/views/components/spacer/Spacer";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import ability from "@/libs/acl/ability";
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    BFormTextarea,
    BTable,
    BCardHeader,
    BCardTitle,
    BCardActions,
    Spacer,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      document_section: {description: "", name: ""},
      company_users: [],
      permission_fields: [{key: 'name', label: i18n.t('Name'),}, {key: 'read', label: i18n.t('Read'),}, {
        key: 'write',
        label: i18n.t('Write')
      }],
      permissionsData: [],
      userData: JSON.parse(localStorage.getItem('userData')),
      userCompany: JSON.parse(localStorage.getItem('userCompany')),
      permissionEnabled: ability.can("manage", "Basic Admin"),
      editDisabled: !ability.can("manage", "Basic Library"),
      allEditDisabled: !ability.can("manage", "Basic Library"),
      document_sections: [],
      new_parent_id: -1,
    }
  },
  computed: {
    currentParent() {
      if (this.document_sections.length >0) {
        let section = this.document_sections.find(l => l.id === this.new_parent_id);
        console.log("currentParent document_sections", this.document_sections)
        console.log("currentParent section", section)
        return section
        //return {id: "1", name: "alfa"}
      }
      return {id: "-1", name: "",}
    },
  },
  methods: {
    refresh() {
      console.log('editDisabled',this.editDisabled)
      const userService = new UserService(useJwt)
      let document_id = this.$route.params.id;
      this.$refs["card"].showLoading = true


      let company_id = store.state.userCompany.id;


      userService.document_sections_filtered(company_id,  this.$route.params.id ,{}).then(response => {
        this.document_sections = response.data.data
        this.document_sections.push({id:-1,name:""})
        console.log('document_sections', this.document_sections)

        });


      if (document_id === 'new') {
        this.document_section.name = "";
        this.document_section.description = "";


        let company_id = store.state.userCompany.id;
        userService.users(company_id).then(response => {
          this.company_users = response.data.data

          this.permissionsData = this.company_users.map(function (value, index) {
            return {
              id: value.id,
              name: value.name + ' ' + value.surname,
              read: false,
              write: false,
              myself: false
            }
          })
          console.log(this.permissionsData)
          for (let permissionDatum of this.permissionsData) {
            if (permissionDatum.id === this.userData.id) {

              permissionDatum.write = true;
              permissionDatum.read = true;
              permissionDatum.myself = true;
            }

          }

          console.log('data', response.data.data)
          console.log('permissionsData', this.permissionsData)
          this.$refs["card"].showLoading = false

        })

      } else {

        userService.document_section(document_id, {}).then(response => {
          this.document_section = response.data.data.section
          console.log('document_section',this.document_section)
          if (this.document_section.parent_id)
          this.new_parent_id = this.document_section.parent_id
          this.company_users = response.data.data.company_users
          this.permissionsData = this.company_users.map(function (value, index) {
            return {
              id: value.id,
              name: value.name + ' ' + value.surname,
              read: value.read === 1,
              write: value.write === 1,
              myself: false
            }
          })

          console.log("userData", this.userData)
          for (let permissionDatum of this.permissionsData) {
            if (permissionDatum.id === this.userData.id) {
              if (this.userData.role !== 'admin') {
                this.editDisabled = (!permissionDatum.write);
              }
              permissionDatum.myself = true;
            }
          }

          console.log('data', response.data.data)
          console.log('permissionsData', this.permissionsData)
          this.$refs["card"].showLoading = false

        })
      }


    },
    async back(e){
      this.$router.push({name: 'document-sections'})

    },
    setParent(document_section) {
      this.new_parent_id = document_section.id;
    },
    async handleSubmit(e) {
      const userService = new UserService(useJwt)

      this.submitted = true;
      //const valid = await this.$validator.validate();
      const valid = true;

      if (!valid) {
        this.$vs.notify({
          title: this.$t("create_admin.FailedToCreateAdmin"),
          text: this.$t("create_admin.ConfirmPasswordFailed"),
          color: "danger",
          position: "top-center"
        });
        this.submitted = false;
        return false;
      }

      this.$refs["card"].showLoading = true
      const data = {company_users: this.permissionsData, document_section: this.document_section, company: this.userCompany, new_parent_id: this.new_parent_id };
      try {
        console.log('document_section', this.document_section)
        console.log('company_users', this.company_users)
        console.log('permissionsData', this.permissionsData)
        console.log('permissionsTable', this.$refs["permissionTable"])
        let document_id = this.$route.params.id;

        userService.update_document_section(document_id, data).then(response => {
          console.log("response", response)

          this.$refs["card"].showLoading = false
          if (response.data.status === "success"){
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: i18n.t('Document section'),
                icon: 'AlertIcon',
                variant: 'success',
                text: i18n.t('Document section saved with success'),
              },
            })
            this.$router.push({name: 'document-sections'})
          }
          else{
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: i18n.t('Document section'),
                icon: 'AlertIcon',
                variant: 'danger',
                text: i18n.t(response.data.message),
              },
            })
          }

        })

        //const response = await createUpdateUser(data);
        const response = {status: 200, data: {id: 1}}
        /*this.$vs.notify({
          title: this.$t("Success"),
          text: this.user_id ? this.$t('UserUpdatedSuccessfull') : this.$t('UserCreatedSucessfull'),
          color: "success",
          position: "top-center"
        });if (response.status === 200 && response.data.id)
          this.$router.push({name: 'ViewUser', params: {id: response.data.id}});

*/

      } catch (error) {
        /*   this.$vs.notify({
             title: this.$t("create_admin.FailedToCreateAdmin"),
             text: error,
             color: "danger",
             position: "top-center"
           }); */
      }
      this.submitted = false;
    },
  },
  mounted() {
    this.refresh()
    //this.$http.get('/good-table/basic')
    //.then(res => { this.rows = res.data })
  },
}
</script>
